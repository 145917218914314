import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import classnames from 'classnames';
import { graphql, navigate } from 'gatsby';

export default function Page({ data }) {
    useEffect(() => {
        if (window.location.pathname !== '/404') {
            navigate('/404');
        }
    }, []);

    const siteTitle = get(data, 'site.siteMetadata.title');
    const klass = classnames('error-page', {
        'error-page--active': true,
    });

    return (
        <div className={klass}>
            <Helmet title={`Not found | ${siteTitle}`} />
        </div>
    );
}

Page.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};

export const pageQuery = graphql`
    query NotFoundQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
